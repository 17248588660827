import React from 'react';
import LandingPage from '../components/organisms/LandingPage/LandingPage';
import Layout from '../layouts';

const App = () => (
  <Layout includeSearchBar={false}>
    <LandingPage />
  </Layout>
);

export default App;
