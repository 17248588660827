import styled from 'styled-components';

const StyledWrapper = styled.div`
    display: flex;
    width: 75%;
    align-self: center;

  .Landing-VR-Left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background-color: #24be51;
  }

  .Landing-VR-Left-Text1 {
    width: 80%;
    margin-top: 5%;
    font-family: Heebo;
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }

  .Landing-VR-Left-Text2 {
    width: 80%;
    margin-bottom: 5%;
    font-family: Heebo;
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: 0.5px;
    text-align: left;
    color: #ffffff;
  }

  .Landing-VR-Left-Break {
    width: 41px;
    opacity: 0.71;
    border: solid 1px #ffffff;
    margin-left: 10%;
    align-self: flex-start;
  }

  .Landing-VR-Left-Button {
    font-family: Heebo;
    margin-bottom: 5%;
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    align-self: flex-start;
    left: 0;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    position: relative;
    align-self: baseline;
    width: 349.1px;
    height: 52px;
    border-radius: 200px;
    border: solid 2px rgba(255, 255, 255, 0.5);
    background: none;
    outline: none;
    overflow:hidden;
    background: none;
    z-index: 1;
    cursor: pointer;
    transition:         0.08s ease-in;
    -o-transition:      0.08s ease-in;
    -ms-transition:     0.08s ease-in;
    -moz-transition:    0.08s ease-in;
    -webkit-transition: 0.08s ease-in;
  }

  .Landing-VR-Left-Button:hover {
    color: #24be51;
    transition: border .2s ease;
    transition: color .1s ease;
    border-color: rgb(255, 255, 255);
  }

  .Landing-VR-Left-Button:before {
    content: "";
    position: absolute;
    background: #ffffff;
    bottom: 0;
    left: 0;
    right: 100%;
    top: 0;
    z-index: -1;
    transition: right 0.15s ease-in;
    -webkit-transition: right 0.15s ease-in;
  }
  .Landing-VR-Left-Button:hover:before {
    right: 0;
  }
  .Landing-VR-Right {
    width: 100%;
  }

  .Landing-VR-Right-Img {
    width: 100%;
    height: 100%;
  }

  .Landing-VR-Button-Wrapper {
    width: 80%;
  }
`;

export default StyledWrapper;