import React from 'react';
import StyledWrapper from './StyledLandingVisibilityReport';
import StockPhoto from '../../../../assets/rectangle-28.png';
import { Flex, Text, Box, Image, Button } from 'rebass/styled-components';

const LandingVisibilityReport = () => {
  return (
    <Flex
      width={1}
      maxWidth={1232}
      mx="auto"
      mt={[4, 10, 14]}
      flexDirection={['column-reverse', null, 'row']}
      flexWrap="wrap"
    >
      <Box width={[1, null, 1 / 2]} bg="brand" px={[10, null, 14]} py={[8, null, 16]}>
        <Text variant="heading2" as="h2" color="white">
          Want to see how your business is being seen online right now? Get a free business listings report.
        </Text>
        <Box height="4px" bg="white" opacity={0.7} width="42px" my={5} />
        <Text variant="heading3" color="white">
          You’ll see how we can help bring new customers to your business with Directory & Business Listings Manager.
        </Text>

        <a href="https://grandio.company.com/visibility-report#" rel="nofollow">
          <Button variant="outline" className="Landing-VR-Left-Button" mt={[8, null, 13]}>
            Get Your Free Business Listings Report
          </Button>
        </a>
      </Box>
      <Box width={[1, null, 1 / 2]}>
        <Box
          width={1}
          height={['430px', null, '100%']}
          sx={{
            backgroundImage: `url('${StockPhoto}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        />
      </Box>
    </Flex>
  );
};
export default LandingVisibilityReport;
