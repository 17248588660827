import React from 'react';
import _ from 'lodash';
import AutoComplete from '../../../atoms/AutoComplete/AutoComplete';
import { Button, Box } from 'rebass/styled-components';

const LandingSearch = (props) => {
  return (
    <>
      <Box width={[1, null, 300]} py={2} mr={[0, null, 4]}>
        <AutoComplete
          id="Business"
          className="Landing-Search-Listing"
          items={props.listings}
          width="100%"
          placeholder="Business Name"
          handleChange={props.handleInputChange}
          handleSelect={props.handleListingSelect}
        />
      </Box>
      <Box width={[1, null, 300]} py={2} mr={[0, null, 4]}>
        <AutoComplete
          id="Category"
          className="Landing-Search-Category"
          items={props.categories}
          placeholder="Category"
          width="100%"
          handleChange={props.handleCategoryChange}
          handleSelect={props.handleCategorySelect}
        />
      </Box>
      <Box width={[1, null, 235]} py={2}>
        <AutoComplete
          id="Location"
          className="Landing-Search-Location"
          items={props.locations}
          width="100%"
          placeholder="Near Atlanta, GA"
          handleChange={props.handleLocationChange}
          handleSelect={props.handleLocationSelect}
        />
      </Box>
      <Button variant="primary" onClick={props.handleSearchSubmit} ml={3}>
        Search
      </Button>
    </>
  );
};

export default LandingSearch;
