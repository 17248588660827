import React, { useState, useEffect } from 'react';
import listingSvc from '../../../services/listingSvc';
// import { Link } from "gatsby"
import { Flex, Text } from 'rebass/styled-components';
import LandingPageWrapper from './StyledLandingPage';
import LandingSearch from '../../molecules/LandingPage/LandingSearch/LandingSearch';
import LandingVisibilityReport from '../../molecules/LandingPage/LandingVisibilityReport/LandingVisibilityReport';
import { navigate } from 'gatsby';

const IndexPage = (props) => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [listingsList, setListingsList] = useState([]);
  const [categoryValue, setCategoryValue] = useState(null);
  const [locationValue, setLocationValue] = useState(null);
  const [listingValue, setListingValue] = useState(null);

  const handleCategoryChange = async (value) => {
    const catagories = await listingSvc().getAutocompleteResults(value);
    setCategoriesList(catagories);
  };
  const handleInputChange = async (value) => {
    let listings;
    if (value !== '') {
      listings = await listingSvc().getListingAutocompleteResults(value.toLowerCase());
    }
    listings !== undefined ? setListingsList(listings) : null;
  };
  const handleLocationChange = async (value) => {
    const locations = await listingSvc().getLocationAutocompleteResults(value);
    setLocationList(locations);
  };

  const handleLocationSelect = (value) => {
    setLocationValue(value);
  };

  const handleListingSelect = (value) => {
    setListingValue(value);
  };

  const handleCategorySelect = (value) => {
    setCategoryValue(value);
  };
  const handleSearchSubmit = () => {
    navigate(
      `/search?${listingValue ? `name=${listingValue}` : ''}${
        listingValue && locationValue ? `&location=${locationValue}` : ''
      }${locationValue && !listingValue ? `location=${locationValue}` : ''}${
        (categoryValue && locationValue) || (categoryValue && listingValue) ? `&category=${categoryValue}` : ''
      }${(categoryValue && !listingValue) || (categoryValue && !locationValue) ? `category=${categoryValue}` : ''} `,
    );
  };

  return (
    <LandingPageWrapper>
      <Text variant="heading1" as="h1" textAlign="center" mt={[7, 8, 14, 15]}>
        Search From Over 8,000,000 Local Businesses
      </Text>
      <Flex
        className="landing-search-container"
        flexDirection={['column', null, 'row']}
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        bg="gray"
        py={[9]}
        px={[10]}
        width={1}
        maxWidth={1232}
        mx="auto"
        sx={{ borderRadius: '8px' }}
        mt={[6, 8, 12, 14]}
      >
        <LandingSearch
          categories={categoriesList}
          locations={locationList}
          listings={listingsList}
          handleCategoryChange={handleCategoryChange}
          handleLocationChange={handleLocationChange}
          handleInputChange={handleInputChange}
          handleSearchSubmit={handleSearchSubmit}
          handleLocationSelect={handleLocationSelect}
          handleListingSelect={handleListingSelect}
          handleCategorySelect={handleCategorySelect}
        />
      </Flex>
      <LandingVisibilityReport />
    </LandingPageWrapper>
  );
};

export default IndexPage;
