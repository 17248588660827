import styled from 'styled-components';
import img from '../../../assets/group-13.png';

const landingPageWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${img});
    background-size: cover;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-family: Heebo;

  @media (max-width: 1232px) {
    .Landing-Search-Form {
      justify-content: space-between;
    }

  @media (max-width: 1232px) {
    .LandingPage-Wrapper {
      height: 100%;
    }
    .Landing-Search-Form {
      justify-content: space-around;
    }
  }

  @media (max-width: 1037px) {
    .Landing-Visibilty-Report {
      flex-direction: column;
      align-items: center;
    }
    .Landing-Search-Input2 {
      margin-top: 15px;
    }
    .Landing-Search-Button {
      margin-top: 15px;
    }
    .Landing-Search-Button {
      transform: translateX(-20px);
    }
    .Landing-Search-Input2 {
      transform: translateX(-20px);
    }
    .Landing-VR-Left-Button {
      width: 70%;
      font-size: 14px;
    }
  }

  @media (max-width: 960px) {
    .Landing-Search-Button{
      margin-top: 10px;
    }
    .Landing-Search-Form {
      max-width: 700px;
    }
    .Landing-VR-Left-Text1 {
      font-size: 28px;
    }
  }

  @media (max-width: 796px) {
    .Landing-Search{
      height: 300px;
    }
    .Landing-Search-Form {
      max-width: 470px;
    }
    .Landing-Search-Input1 {
      margin-bottom: 10px;
    }
    .Landing-Search-Input2 {
      margin-bottom: 10px;
      margin-top: 0;
      transform: translateX(0);
    }
    .Landing-Search-Button {
      margin: 0;
      margin-top: 0;
      transform: translateX(0);
    }
    .Landing-Search-Categories {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 630px) {
    .Landing-Search-Input1 {
      margin-bottom: 10px;
    }
    .Landing-Search-Input2 {
      margin-bottom: 10px;
    }
    .Landing-Search-Categories {
      margin-bottom: 10px;
    }
  }
`;
export default landingPageWrapper;
